<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <h2 class="text-2xl font-weight-semibold text--primary">
          <span class="me-2">Page Not Found</span>
          <v-icon color="warning"> {{ icons.mdiAlert }} </v-icon>
        </h2>
        <p class="text-sm">
          we couldn't find the page you are looking for
        </p>

        <router-link to="/result">
          <v-btn color="primary" class="mb-4">Back to result page</v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiAlert } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
